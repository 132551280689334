<template>
  <el-card shadow="never">
    <el-row style="margin-bottom: 22px">
      <el-input v-model="queryInfo.materialCode" class="input" placeholder="备件编码" clearable></el-input>
      <el-button type="primary" @click="currentChange(1)" :loading="loading">查询</el-button>
      <el-button type="primary" @click="importBatch = true">覆盖导入</el-button>
    </el-row>

    <el-table :data="ruleList" border stripe>
      <el-table-column align="center" prop="materialCode" label="校色前FRU编码"></el-table-column>
      <el-table-column align="center" prop="ppn" label="校色前PPN编码"></el-table-column>
      <el-table-column align="center" prop="amount" label="需入合格数量"></el-table-column>
      <el-table-column align="center" prop="createUserName" label="创建人"></el-table-column>
      <el-table-column align="center" prop="createTime" label="创建时间"></el-table-column>
      <el-table-column align="center" label="操作" width="80px" fixed="right">
        <template slot-scope="scope">
          <el-button @click="deleteRule(scope.row)" type="danger" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page-box">
      <el-pagination background layout="total,prev, pager, next" :page-size="pageSize" :total="total" @current-change="currentChange" :current-page.sync="queryInfo.pageNo"></el-pagination>
    </div>

    <el-dialog title="覆盖导入" :visible.sync="importBatch" :close-on-click-modal="false" width="800px" :before-close="importClose">
      <ImportComponent template-url="template/ruleColorCalibration.xlsx" upload-url="ruleColorCalibration/importBatch"/>
      <div slot="footer" class="dialog-footer">
        <el-button @click="importClose">关闭</el-button>
      </div>
    </el-dialog>

  </el-card>
</template>

<script>
import ImportComponent from "@/components/ImportComponent.vue";

export default {
  name: "RuleColorCalibration",
  components: {ImportComponent},
  data() {
    return {
      queryInfo: {
        materialCode: '',
        supplierCode: '',
        lendOrderCode: '',
        disabled: false,
        poDoc: '',
        assetSn: '',
        pageNo: 1
      },
      formData: {
        materialCode: '',
        materialCnt: 0,
        supplierCode: '',
        lendOrderCode: '',
        poDoc: '',
        assetSn: ''
      },
      total: 0,
      pageSize: 0,
      ruleList: [],
      supplierList: [],
      dialogVisible: false,
      loading: false,
      importBatch: false
    }
  },
  created() {
    this.search();
  },
  methods: {
    async search() {
      this.loading = true;
      const {data: res} = await this.$axios.post('ruleColorCalibration/queryList', this.queryInfo);
      this.loading = false;
      if (res.code !== 0) {
        return this.$message.error(res.message);
      }
      this.ruleList = res.data.records;
      this.total = res.data.total;
      this.pageSize = res.data.size;
    },
    currentChange(pageNo) {
      this.queryInfo.pageNo = pageNo;
      this.search()
    },
    deleteRule(row) {
      this.$confirm('确定删除吗？', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning',
      }).then(() => {
        this.$axios.post('ruleColorCalibration/deleteRule', row.id).then(response => {
          if (response.data.code !== 0) {
            return this.$message.error(response.data.message);
          }
          this.$message.success("删除成功");
          this.search();
        })
      })
    },
    handleClose() {
      this.dialogVisible = false;
    },
    importClose() {
      this.search();
      this.importBatch = false;
    },
  }
}
</script>

<style scoped>
.input {
  width: 200px;
  margin-right: 10px;
}
</style>